import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';
import './App.scss';
import LoadingSpinner from '../src/views/Loader'

const loading = () => <LoadingSpinner/>;

// Containers
const DefaultLayout = Loadable({
  loader: () => import('./containers/DefaultLayout'),
  loading
});

const Login = Loadable({
  loader: () => import('./views/Login'),
  loading
});


class App extends Component {
  render() {
    return (
      <HashRouter>
          <Switch>
            <Route path="/login" name="Login Page" component={Login} />
            <Route path="/" name="Challan" component={DefaultLayout} />
          </Switch>
      </HashRouter>
    );
  }
}

export default App;
